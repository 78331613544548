import React, { useState, useRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import PrivacyBanner from "./PrivacyBanner";
import StepBanner from "./StepBanner";

const AppPage5 = (props) => {
  const [imageURL, setImageURL] = useState(null);
  const { handleSig, back, handleSubmit } = props;

  const handleClick = (e) => {
    e.preventDefault();
    const newImageURL = sigCanvas.current.toDataURL("image/png");
    setImageURL(newImageURL);
    handleSig(newImageURL);
  };

  const clear = (e) => {
    e.preventDefault();
    sigCanvas.current.clear();
  };

  const sigCanvas = useRef({});
  return (
    <>
      <StepBanner currentStep={5} />
      <div className='flex items-center justify-center mb-10'>
        <div className='xl:w-10/12 w-full px-8'>
          <div className='xl:px-24'>
            <PrivacyBanner />
            <div className=' border-b border-gray pb-16 mb-4'>
              <div className=''>
                <br />
                <br />
                <h1 className='text-2xl text-center underline underline-offset-8'>
                  Disclosures
                </h1>
                <br />
                <p>
                  As a condition of employment, Bristol Place Corporation may
                  require a DHS background study you must successfully pass the
                  background study to work directly with vulnerable adults.
                  Further information will be provided if applicable.
                </p>
                <br />
                <p>
                  If the position being applied for requires driving, you must
                  provide proof of vaid auto insurance. A motor vehicle
                  Report will determine if the employee meets the necessary
                  requirements as they pertain to an employee driving employer's
                  vehicle and/or use of an employee's vehicle on the job.
                  Further information will be provided if applicable.
                </p>
                <br />
                <p>
                  {" "}
                  If you become an employee of Bristol place, your employment
                  will be employment "at-will". This means either you or the
                  company may terminate the employment relationship at any time
                  with or without cause or advance notice and the company may
                  also change the terms and conditions of your employment with
                  or without cause or advanced notice.
                </p>
                <br />
                <p>
                  By my signature below, I certify that I have read and
                  understand the information and instructions in this employment
                  application and I verify the truth and accuracy of the
                  statements I have made in this application. I further understand that
                  Bristol Place Corporation will rely upon the accuracy of these statements
                  in making its hiring decision in that any false statement or
                  material omission will be grounds for denying or terminating
                  employment.
                </p>
              </div>
              <br />
              <br />
              <div className='mx-auto w-4/6 capitalize dark:text-white text-center underline underline-offset-8'>
                <label
                  className='text-black text-2xl'
                  htmlFor={"signature"}
                  id='signature'
                >
                  Digital Signature
                </label>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    className: "signatureCanvas bg-bristol-blue-2 rounded-lg",
                  }}
                />
                <button
                  className='rounded-full bg-bristol-blue-2 bg-opacity-50 p-4 m-4'
                  onClick={clear}
                >
                  Clear
                </button>
                <button
                  className='rounded-full bg-bristol-blue-2 bg-opacity-50 p-4 m-4'
                  onClick={handleClick}
                >
                  Accept
                </button>
              </div>
            </div>
            {imageURL ? (
              <img
                src={imageURL}
                alt='my signature'
                style={{
                  display: "block",
                  margin: "auto",
                  border: "6px solid black",
                  width: "250px",
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className='app-btn-container flex -mt-8 mb-6 justify-around'>
      <button
          className='rounded-full p-3 bg-bristol-blue-2 bg-opacity-50'
          onClick={back}
        >
          ← Previous
        </button>
        <button
          className='rounded-full p-3 bg-bristol-blue-2 bg-opacity-50 w-64'
          onSubmit={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
};
export default AppPage5;
