import React from "react";
import PrivacyBanner from "./PrivacyBanner";
import StepBanner from "./StepBanner";
import { useForm } from "react-hook-form";

const AppPage2 = (props) => {
  const { data, handleChange, next, back } = props;
  const { register, trigger, formState: { errors } } = useForm({
    mode: "onBlur"
  });
  return (
    <>
      <StepBanner currentStep={2} />
      <div className='flex items-center justify-center mb-10'>
        <div className='xl:w-10/12 w-full px-8'>
          <div className='xl:px-24'>
            <PrivacyBanner />
            <div className='mt-16 lg:flex justify-between border-b border-gray pb-16 mb-4'>
              <div className='left-section lg:w-2/6'>
                <div className='flex items-center'>
                  <h1 className='text-xl font-medium pr-2 leading-5'>
                    Educational Background
                  </h1>
                </div>
                <p className='mt-4 text-sm leading-5 '>
                  Please provide the following information about your education.
                </p>
              </div>
              <div className='right-section lg:w-4/6'>
                <div className='md:flex items-center lg:ml-24 lg:mt-0 mt-4'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='highSchool'
                      htmlFor='highSchool'
                    >
                      High School:
                    </label>
                    <input
                      {...register('highSchool', { required: true })}
                      type='text'
                      name='highSchool'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='High School Name'
                      value={data.highSchool}
                      onChange={handleChange}
                    />
                    {errors.highSchool && <p id="error" className="text-red">This Field Is Required</p>}
                  </div>
                  <div className='md:w-full md:ml-6 md:mt-0 mt-4'>
                    <label
                      className='text-sm leading-none '
                      id='yearGraduated'
                      htmlFor='yearGraduated'
                    >
                      Year Graduated:
                    </label>
                    <input
                      {...register('yearGraduated', { required: true })}
                      name='yearGraduated'
                      type='number'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='Year'
                      value={data.yearGraduated}
                      onChange={handleChange}
                    />
                    {errors.yearGraduated && <p id="error" className="text-red">This Field Is Required</p>}
                  </div>
                </div>
                <div className='md:flex items-center lg:ml-24 mt-8'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='collegeAttended'
                      htmlFor='collegeAttended'
                    >
                      College(s) Attended:
                    </label>
                    <input
                      {...register('collegeAttended', { required: true })}
                      name='collegeAttended'
                      type='text'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='College Name'
                      value={data.collegeAttended}
                      onChange={handleChange}
                    />
                    {errors.collegeAttended && <p id="error" className="text-red">This Field Is Required</p>}
                  </div>
                </div>
                <div className='md:flex items-center lg:ml-24 mt-8'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='degreesEarned'
                      htmlFor='degreesEarned'
                    >
                      Degree(s) Earned:
                    </label>
                    <input
                      {...register('degreesEarned', { required: true })}
                      name='degreesEarned'
                      type='text'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='B.A. Social Work'
                      value={data.degreesEarned}
                      onChange={handleChange}
                    />
                    {errors.degreesEarned && <p id="error" className="text-red">This Field Is Required</p>}
                  </div>
                </div>
                <div className='md:flex items-center lg:ml-24 mt-8'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='postGradName'
                      htmlFor='postGradName'
                    >
                      Post-Graduate School Name:
                    </label>
                    <input
                      {...register('postGradName', { required: false })}
                      name='postGradName'
                      type='email'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='Post-Graduate School Name'
                      value={data.postGradName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='md:flex items-center lg:ml-24 mt-8'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='postGradDegreesEarned'
                      htmlFor='postGradDegreesEarned'
                    >
                      Post-Graduate Degree(s) Earned:
                    </label>
                    <input
                      {...register('postGradDegreesEarned', { required: false })}
                      name='postGradDegreesEarned'
                      type='text'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder=' M.A. Social Work'
                      value={data.postGradDegreesEarned}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='md:flex items-center lg:ml-24 mt-8'>
                  <div className='md:w-full'>
                    <label
                      className='text-sm leading-none '
                      id='otherXP'
                      htmlFor='otherXP'
                    >
                      Please list any experiences, skills, or qualifications
                      which would especially prepare you for this position:
                    </label>
                    <textarea
                      {...register('otherXP', { required: true })}
                      name='otherXP'
                      type='text'
                      tabIndex='0'
                      className='w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none '
                      placeholder='Experience'
                      value={data.otherXP}
                      onChange={handleChange}
                    />
                    {errors.otherXP && <p id="error" className="text-red">This Field Is Required</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='app-btn-container flex -mt-8 mb-6 justify-around'>
        <button
          className='rounded-full p-3  bg-bristol-blue-2 bg-opacity-50'
          onClick={back}
        >
          ← Previous
        </button>
        <button
          onClick={async (e) => {
            e.preventDefault();
            const result = await trigger();
            if (result === true) {
              next()
            } else {
              return null
            }
          }}
          className='rounded-full p-3 bg-bristol-blue-2 bg-opacity-50'
        >
          Next Page →
        </button>
      </div>
    </>
  );
};

export default AppPage2;
