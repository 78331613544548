import React from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import PrivacyBanner from "./PrivacyBanner";
import StepBanner from "./StepBanner";

const AppPage4 = (props) => {
  const { handleAttachment, data, next, back } = props;

  return (
    <>
      <StepBanner currentStep={4} />
      <div className='flex items-center justify-center mb-10'>
        <div className='xl:w-10/12 w-full px-8'>
          <div className='xl:px-24'>
            <PrivacyBanner />
            <div className='mt-16  border-b border-gray-200 pb-16'>
              <div className='py-10 border rounded-lg px-2'>
                <div className='max-w-md mx-auto bg-white rounded-lg overflow-hidden md:max-w-lg'>
                  <div className='md:flex'>
                    <div className='w-full'>
                      <div className='p-4 border-b-2'>
                        {" "}
                        <span className='text-lg font-bold'>
                          Upload Resume
                        </span>{" "}
                      </div>
                      <div className='p-3'>
                        {/* <div className='mb-2'>
                          {" "}
                          <span className='text-sm'>Title</span>{" "}
                          <input
                            type='text'
                            className='h-12 px-3 w-full border-gray border rounded focus:outline-none focus:border-black'
                          />{" "}
                        </div> */}
                        <div className='mb-2 cursor-pointer'>
                          <span>Attachments</span>
                          <div className='relative h-40 rounded-lg border-dashed border-2 border-gray bg-white flex justify-center items-center hover:cursor-pointer'>
                            <div className='absolute'>
                              <div className='flex flex-col items-center '>
                                <FaCloudUploadAlt className='text-bristol-blue-2 text-5xl' />
                                <span className='block'>
                                  Drop files here
                                </span>
                                <span className='block font-normal'>or</span>
                                <button className='rounded bg-bristol-blue-2 bg-opacity-50 px-4 py-2  font-normal '>
                                  Browse files
                                </button>
                              </div>
                            </div>
                            <input
                              type='file'
                              name='attachment'
                              className='h-full w-full opacity-0 cursor-pointer'
                              onChange={handleAttachment}
                            />
                          </div>
                          <p>Attachment = {data.attachment.name}</p>
                          <div className='flex justify-between items-center text-gray'>
                            <span>Accepted file type:.doc only</span>{" "}
                            <span className='flex items-center '>
                              <i className='fa fa-lock mr-1' /> secure
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='app-btn-container flex -mt-8 mb-6 justify-around'>
        <button
          className='rounded-full p-3  bg-bristol-blue-2 bg-opacity-50'
          onClick={back}
        >
          ← Previous
        </button>
        <button
          onClick={next}
          className='rounded-full p-3  bg-bristol-blue-2 bg-opacity-50'
        >
          Next Page →
        </button>
      </div>
    </>
  );
};

export default AppPage4;
