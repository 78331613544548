import React from "react";
import PrivacyBanner from "./PrivacyBanner";
import StepBanner from "./StepBanner";
import { useForm } from "react-hook-form";

const AppPage3 = (props) => {
  const { data, handleChange, next, back } = props;
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  return (
    <>
      <StepBanner currentStep={3} />
      <div className="flex items-center justify-center mb-10">
        <div className="xl:w-10/12 w-full px-8">
          <div className="xl:px-24">
            <PrivacyBanner />
            <div className="mt-16 lg:flex justify-between border-b border-gray pb-16">
              <div className="left-section lg:w-2/6">
                <div className="flex items-center">
                  <h1 className="text-xl font-medium pr-2 leading-5">
                    Employment History
                  </h1>
                </div>
                <p className="mt-4 text-sm leading-5">
                  List Previous employers; begin with most recent employment.
                </p>
              </div>
              <div className="flex flex-col right-section lg:w-4/6">
                <div>
                  <div className="md:flex items-center lg:ml-24 lg:mt-0 mt-4">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none"
                        id="employer1Name"
                        htmlFor="employer1Name"
                      >
                        Employer 1:
                      </label>
                      <input
                        {...register("employer1Name", { required: true })}
                        name="employer1Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Company Name"
                        value={data.employer1Name}
                        onChange={handleChange}
                      />
                      {errors.employer1Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none"
                        id="employer1Dates"
                        htmlFor="employer1Dates"
                      >
                        Dates of Employment:
                      </label>
                      <input
                        {...register("employer1Dates", { required: true })}
                        name="employer1Dates"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="xx/xx/xxxx"
                        value={data.employer1Dates}
                        onChange={handleChange}
                      />
                      {errors.employer1Dates && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>

                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none "
                        id="employer1Phone"
                        htmlFor="employer1Phone"
                      >
                        Employer 1 Phone:
                      </label>
                      <input
                        {...register("employer1Phone", { required: true })}
                        name="employer1Phone"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="###-###-####"
                        value={data.employer1Phone}
                        onChange={handleChange}
                      />
                      {errors.employer1Phone && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none"
                        id="employer1Pay"
                        htmlFor="employer1Pay"
                      >
                        Rate of Pay:
                      </label>
                      <input
                        {...register("employer1Pay", { required: true })}
                        name="employer1Pay"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Hourly Rate/Salary"
                        value={data.employer1Pay}
                        onChange={handleChange}
                      />
                      {errors.employer1Pay && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none"
                        id="supervisor1Name"
                        htmlFor="supervisor1Name"
                      >
                        Supervisor's Name:
                      </label>
                      <input
                        {...register("supervisor1Name", { required: true })}
                        name="supervisor1Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Supervisor's Name"
                        value={data.supervisor1Name}
                        onChange={handleChange}
                      />
                      {errors.supervisor1Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>



                  <div className="md:flex items-center lg:ml-24 mt-8">
<div className="md:w-full">
  <label
    className="text-sm leading-none text-black"
    id="employer1Role"
    htmlFor="employer1Role"
  >
    Job Title:
  </label>
  <textarea
    {...register("employer1Role", { required: true })}
    name="employer1Role"
    type="text"
    tabIndex="0"
    className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
    placeholder="Job Title:"
    value={data.employer1Role}
    onChange={handleChange}
  />
  {errors.employer1Role && (
    <p id="error" className="text-red">
      This Field Is Required.
    </p>
  )}
</div>
</div>




                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer1Details1"
                        htmlFor="employer1Details1"
                      >
                        Describe in detail, your job responsibilities.
                      </label>
                      <textarea
                        {...register("employer1Details1", { required: true })}
                        name="employer1Details1"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your job responsibilities."
                        value={data.employer1Details1}
                        onChange={handleChange}
                      />
                      {errors.employer1Details1 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer1Details2"
                        htmlFor="employer1Details2"
                      >
                        Describe in detail, your reason for leaving:
                      </label>
                      <textarea
                        {...register("employer1Details2", { required: true })}
                        name="employer1Details2"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your reason for leaving:"
                        value={data.employer1Details2}
                        onChange={handleChange}
                      />
                      {errors.employer1Details2 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="md:flex items-center lg:ml-24 lg:mt-0 mt-4">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer2Name"
                        htmlFor="employer2Name"
                      >
                        Employer 2:
                      </label>
                      <input
                        {...register("employer2Name", { required: true })}
                        name="employer2Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Company Name"
                        value={data.employer2Name}
                        onChange={handleChange}
                      />
                      {errors.employer2Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer2Dates"
                        htmlFor="employer2Dates"
                      >
                        Dates of Employment:
                      </label>
                      <input
                        {...register("employer2Dates", { required: true })}
                        name="employer2Dates"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="xx/xx/xxxx"
                        value={data.employer2Dates}
                        onChange={handleChange}
                      />
                      {errors.employer2Dates && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>

                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none "
                        id="employer2Phone"
                        htmlFor="employer2Phone"
                      >
                        Employer 2 Phone:
                      </label>
                      <input
                        {...register("employer2Phone", { required: true })}
                        name="employer2Phone"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="###-###-####"
                        value={data.employer2Phone}
                        onChange={handleChange}
                      />
                      {errors.employer2Phone && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer2Pay"
                        htmlFor="employer2Pay"
                      >
                        Rate of Pay:
                      </label>
                      <input
                        {...register("employer2Pay", { required: true })}
                        name="employer2Pay"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Hourly Rate/Salary"
                        value={data.employer2Pay}
                        onChange={handleChange}
                      />
                      {errors.employer2Pay && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none "
                        id="supervisor2Name"
                        htmlFor="supervisor2Name"
                      >
                        Supervisor's Name:
                      </label>
                      <input
                        {...register("supervisor2Name", { required: true })}
                        name="supervisor2Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Supervisor's Name"
                        value={data.supervisor2Name}
                        onChange={handleChange}
                      />
                      {errors.supervisor2Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>


                  <div className="md:flex items-center lg:ml-24 mt-8">
<div className="md:w-full">
  <label
    className="text-sm leading-none text-black"
    id="employer2Role"
    htmlFor="employer2Role"
  >
    Job Title:
  </label>
  <textarea
    {...register("employer2Role", { required: true })}
    name="employer2Role"
    type="text"
    tabIndex="0"
    className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
    placeholder="Job Title:"
    value={data.employer2Role}
    onChange={handleChange}
  />
  {errors.employer2Role && (
    <p id="error" className="text-red">
      This Field Is Required.
    </p>
  )}
</div>
</div> 




                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer2Details1"
                        htmlFor="employer2Details1"
                      >
                        Describe in detail, your job responsibilities:
                      </label>
                      <textarea
                        {...register("employer2Details1", { required: true })}
                        name="employer2Details1"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your job responsibilities.:"
                        value={data.employer2Details1}
                        onChange={handleChange}
                      />
                      {errors.employer2Details1 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer2Details2"
                        htmlFor="employer2Details2"
                      >
                        Describe in detail, your reason for leaving:
                      </label>
                      <textarea
                        {...register("employer2Details2", { required: true })}
                        name="employer2Details2"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your reason for leaving:"
                        value={data.employer2Details2}
                        onChange={handleChange}
                      />
                      {errors.employer2Details2 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div className="md:flex items-center lg:ml-24 lg:mt-0 mt-4">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer3Name"
                        htmlFor="employer3Name"
                      >
                        Employer 3:
                      </label>
                      <input
                        {...register("employer3Name", { required: true })}
                        name="employer3Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Company Name"
                        value={data.employer3Name}
                        onChange={handleChange}
                      />
                      {errors.employer3Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer3Dates"
                        htmlFor="employer3Dates"
                      >
                        Dates of Employment:
                      </label>
                      <input
                        {...register("employer3Dates", { required: true })}
                        name="employer3Dates"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="xx/xx/xxxx"
                        value={data.employer3Dates}
                        onChange={handleChange}
                      />
                      {errors.employer3Dates && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>

                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none "
                        id="employer3Phone"
                        htmlFor="employer3Phone"
                      >
                        Employer 3 Phone:
                      </label>
                      <input
                        {...register("employer3Phone", { required: true })}
                        name="employer3Phone"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="###-###-####"
                        value={data.employer3Phone}
                        onChange={handleChange}
                      />
                      {errors.employer3Phone && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none "
                        id="employer3Pay"
                        htmlFor="employer3Pay"
                      >
                        Rate of Pay:
                      </label>
                      <input
                        {...register("employer3Pay", { required: true })}
                        name="employer3Pay"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Hourly Rate/Salary"
                        value={data.employer3Pay}
                        onChange={handleChange}
                      />
                      {errors.employer3Pay && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                    <div className="md:w-full md:ml-12 md:mt-0 mt-4">
                      <label
                        className="text-sm leading-none "
                        id="supervisor3Name"
                        htmlFor="supervisor3Name"
                      >
                        Supervisor's Name:
                      </label>
                      <input
                        {...register("supervisor3Name", { required: true })}
                        name="supervisor3Name"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Supervisor's Name"
                        value={data.supervisor3Name}
                        onChange={handleChange}
                      />
                      {errors.supervisor3Name && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>



                  <div className="md:flex items-center lg:ml-24 mt-8">
<div className="md:w-full">
  <label
    className="text-sm leading-none text-black"
    id="employer3Role"
    htmlFor="employer3Role"
  >
    Job Title:
  </label>
  <textarea
    {...register("employer3Role", { required: true })}
    name="employer3Role"
    type="text"
    tabIndex="0"
    className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
    placeholder="Job Title:"
    value={data.employer3Role}
    onChange={handleChange}
  />
  {errors.employer3Role && (
    <p id="error" className="text-red">
      This Field Is Required.
    </p>
  )}
</div>
</div>





                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer3Details1"
                        htmlFor="employer3Details1"
                      >
                        Describe in detail, your job responsibilities.
                      </label>
                      <textarea
                        {...register("employer3Details1", { required: true })}
                        name="employer3Details1"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your job responsibilities."
                        value={data.employer3Details1}
                        onChange={handleChange}
                      />
                      {errors.employer3Details1 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex items-center lg:ml-24 mt-8">
                    <div className="md:w-full">
                      <label
                        className="text-sm leading-none text-black"
                        id="employer3Details2"
                        htmlFor="employer3Details2"
                      >
                        Describe in detail, your reason for leaving:
                      </label>
                      <textarea
                        {...register("employer3Details2", { required: true })}
                        name="employer3Details2"
                        type="text"
                        tabIndex="0"
                        className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                        placeholder="Describe in detail, your reason for leaving:"
                        value={data.employer3Details2}
                        onChange={handleChange}
                      />
                      {errors.employer3Details2 && (
                        <p id="error" className="text-red">
                          This Field Is Required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16 lg:flex border-b border-gray pb-16 mb-4">
              <div className="left-section lg:w-2/6">
                <div className="flex items-center">
                  <h1 className="text-xl font-medium pr-2 leading-5 ">
                    References
                  </h1>
                </div>
                <p className="mt-4 text-sm leading-5 ">
                  List three references and their contact information.
                </p>
              </div>
              <div className="right-section lg:w-4/6">
                <div className="md:flex justify-between items-center lg:ml-24 mt-4">
                  <div className="md:w-48">
                    <label
                      className="text-sm leading-none "
                      id="ref1Name"
                      htmlFor="ref1Name"
                    >
                      Reference 1:
                    </label>
                    <input
                      {...register("ref1Name", { required: true })}
                      name="ref1Name"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg  border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none text-black"
                      placeholder="Reference Name"
                      value={data.ref1Name}
                      onChange={handleChange}
                    />
                    {errors.ref1Name && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4 ">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref1Relation"
                      htmlFor="ref1Relation"
                    >
                      Relationship to Applicant
                    </label>
                    <input
                      {...register("ref1Relation", { required: true })}
                      name="ref1Relation"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="Relationship"
                      value={data.ref1Relation}
                      onChange={handleChange}
                    />
                    {errors.ref1Relation && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>{" "}
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref1Phone"
                      htmlFor="ref1Phone"
                    >
                      Reference 1 Phone:
                    </label>
                    <input
                      {...register("ref1Phone", { required: true })}
                      name="ref1Phone"
                      type="name"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="###-###-####"
                      value={data.ref1Phone}
                      onChange={handleChange}
                    />
                    {errors.ref1Phone && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="md:flex justify-between items-center lg:ml-24 mt-4">
                  <div className="md:w-48">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref2Name"
                      htmlFor="ref2Name"
                    >
                      Reference 2:
                    </label>
                    <input
                      {...register("ref2Name", { required: true })}
                      name="ref2Name"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none text-black"
                      placeholder="Reference Name"
                      value={data.ref2Name}
                      onChange={handleChange}
                    />
                    {errors.ref2Name && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref2Relation"
                      htmlFor="ref2Relation"
                    >
                      Relationship to Applicant
                    </label>
                    <input
                      {...register("ref2Relation", { required: true })}
                      name="ref2Relation"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="Relationship"
                      value={data.ref2Relation}
                      onChange={handleChange}
                    />
                    {errors.ref2Relation && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>{" "}
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref2Phone"
                      htmlFor="ref2Phone"
                    >
                      Reference 2 Phone:
                    </label>
                    <input
                      {...register("ref2Phone", { required: true })}
                      name="ref2Phone"
                      type="name"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="###-###-####"
                      value={data.ref2Phone}
                      onChange={handleChange}
                    />
                    {errors.ref2Phone && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="md:flex justify-between	items-center lg:ml-24 mt-4">
                  <div className="md:w-48">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref3Name"
                      htmlFor="ref3Name"
                    >
                      Reference 3:
                    </label>
                    <input
                      {...register("ref3Name", { required: true })}
                      name="ref3Name"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none text-black"
                      placeholder="Reference Name"
                      value={data.ref3Name}
                      onChange={handleChange}
                    />
                    {errors.ref3Name && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref3Relation"
                      htmlFor="ref3Relation"
                    >
                      Relationship to Applicant
                    </label>
                    <input
                      {...register("ref3Relation", { required: true })}
                      name="ref3Relation"
                      type="text"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="Relationship"
                      value={data.ref3Relation}
                      onChange={handleChange}
                    />
                    {errors.ref3Relation && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>{" "}
                  <div className="md:w-48 md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none whitespace-nowrap"
                      id="ref3Phone"
                      htmlFor="ref3Phone"
                    >
                      Reference 3 Phone:
                    </label>
                    <input
                      {...register("ref3Phone", { required: true })}
                      name="ref3Phone"
                      type="name"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray-200 focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      placeholder="###-###-####"
                      value={data.ref3Phone}
                      onChange={handleChange}
                    />
                    {errors.ref3Phone && (
                      <p id="error" className="text-red">
                        This Field Is Required.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-btn-container flex -mt-8 mb-6 justify-around">
        <button
          className="rounded-full p-3 bg-bristol-blue-2 bg-opacity-50"
          onClick={back}
        >
          ← Previous
        </button>
        <button
          onClick={async (e) => {
            e.preventDefault();
            const result = await trigger();
            if (result === true) {
              next();
            } else {
              return null;
            }
          }}
          className="rounded-full p-3 bg-bristol-blue-2 bg-opacity-50"
        >
          Next Page →
        </button>
      </div>
    </>
  );
};

export default AppPage3;
