import React, { useState } from "react";
import Layout from "../../components/Layout";
import { navigate } from "gatsby-link";
import AppPage1 from "../../components/AppPage1";
import AppPage2 from "../../components/AppPage2";
import AppPage3 from "../../components/AppPage3";
import AppPage4 from "../../components/AppPage4";
import AppPage5 from "../../components/AppPage5";

export default function Index() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    homeAddress: "",
    city: "",
    state: "",
    zipCode: "",
    socialSecurity: "",
    phoneNumber: "",
    emailAddress: "",
    workType: "",
    desiredPosition: "",
    howDiscovered: "",
    referredBy: "",
    availableStartDate: "",
    desiredPayRate: "",
    //  end of page 1

    highSchool: "",
    yearGraduated: "",
    collegeAttended: "",
    degreesEarned: "",
    postGradName: "",
    postGradDegreesEarned: "",
    otherXP: "",
    //end of page 2

    employer1Name: "",
    employer1Dates: "",
    employer1Phone: "",
    employer1Pay: "",
    supervisor1Name: "",
    employer1Role: "",
    employer1Details1: "",
    employer1Details2: "",


    employer2Name: "",
    employer2Dates: "",
    employer2Phone: "",
    employer2Pay: "",
    supervisor2Name: "",
    employer2Role: "",
    employer2Details1: "",
    employer2Details2: "",


    employer3Name: "",
    employer3Dates: "",
    employer3Phone: "",
    employer3Pay: "",
    supervisor3Name: "",
    employer3Role: "",
    employer3Details1: "",
    employer3Details2: "",

    ref1Name: "",
    ref1Relation: "",
    ref1Phone: "",
    ref2Name: "",
    ref2Relation: "",
    ref2Phone: "",
    ref3Name: "",
    ref3Relation: "",
    ref3Phone: "",
    //end of page 3
    attachment: "",
    //  end of page 4
    sig: "",
    //  End of page 5
  });

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSig = (sig) => {
    setFormData({
      ...formData,
      sig,
    });
  };

  const handleAttachment = (e) => {
    console.log("handle attachement fired")
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const next = () => {
    // e.preventDefault();
      setCurrentStep(currentStep + 1);
      scrollToTop();
  };

  const back = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
    scrollToTop();
  };

  function encode(data) {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }
  
  const handleSubmit = (e) => {
    const form = e.target;
    e.preventDefault();
    fetch("/", {
      method: "POST",
      body: encode({ "form-name": "application", ...formData }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function renderSwitch(currentStep) {
    switch (currentStep) {
      case 1:
        return (
          <AppPage1
            currentStep={currentStep}
            data={formData}
            handleChange={handleChange}
            next={next}
          />
        );
      case 2:
        return (
          <AppPage2
            data={formData}
            handleChange={handleChange}
            next={next}
            back={back}
          />
        );
      case 3:
        return (
          <AppPage3
            data={formData}
            handleChange={handleChange}
            next={next}
            back={back}
          />
        );
      case 4:
        return (
          <AppPage4
            data={formData}
            handleChange={handleChange}
            handleAttachment={handleAttachment}
            next={next}
            back={back}
          />
        );
      default:
        return (
          <AppPage5
            data={formData}
            handleChange={handleChange}
            handleSig={handleSig}
            back={back}
          />
        );
    }
  }

  return (

    <Layout className='font-sans'>
      <form
        className='flex flex-col mx-auto'
        method='post'
        action='/contact/thanks/'
        onSubmit={handleSubmit}
      >
        {renderSwitch(currentStep)}
      </form>
      <form
        name='application'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        hidden
      >
        <input type={"text"} name={"firstName"} />
        <input type={"text"} name={"lastName"} />
        <input type={"text"} name={"homeAddress"} />
        <input type={"text"} name={"city"} />
        <input type={"text"} name={"state"} />
        <input type={"text"} name={"zipCode"} />
        <input type={"text"} name={"socialSecurity"} />
        <input type={"text"} name={"phoneNumber"} />
        <input type={"text"} name={"emailAddress"} />
        <input type={"text"} name={"workType"} />
        <input type={"text"} name={"desiredPosition"} />
        <input type={"text"} name={"howDiscovered"} />
        <input type={"text"} name={"referredBy"} />
        <input type={"text"} name={"availableStartDate"} />
        <input type={"text"} name={"desiredPayRate"} />
        <input type={"text"} name={"highSchool"} />
        <input type={"text"} name={"yearGraduated"} />
        <input type={"text"} name={"collegeAttended"} />
        <input type={"text"} name={"degreesEarned"} />
        <input type={"text"} name={"postGradName"} />
        <input type={"text"} name={"postGradDegreesEarned"} />
        <input type={"text"} name={"otherXP"} />

        <input type={"text"} name={"employer1Name"} />
        <input type={"text"} name={"employer1Dates"} />
        <input type={"text"} name={"employer1Phone"} />
        <input type={"text"} name={"employer1Pay"} />
        <input type={"text"} name={"supervisor1Name"} />
        <input type={"text"} name={"employer1Role"} />
        <input type={"text"} name={"employer1Details1"} />
        <input type={"text"} name={"employer1Details2"} />

        <input type={"text"} name={"employer2Name"} />
        <input type={"text"} name={"employer2Dates"} />
        <input type={"text"} name={"employer2Phone"} />
        <input type={"text"} name={"employer2Pay"} />
        <input type={"text"} name={"supervisor2Name"} />
        <input type={"text"} name={"employer2Role"} />
        <input type={"text"} name={"employer2Details1"} />
        <input type={"text"} name={"employer2Details2"} />

        <input type={"text"} name={"employer3Name"} />
        <input type={"text"} name={"employer3Dates"} />
        <input type={"text"} name={"employer3Phone"} />
        <input type={"text"} name={"employer3Pay"} />
        <input type={"text"} name={"supervisor3Name"} />
        <input type={"text"} name={"employer3Role"} />
        <input type={"text"} name={"employer3Details1"} />
        <input type={"text"} name={"employer3Details2"} />

        <input type={"text"} name={"ref1Name"} />
        <input type={"text"} name={"ref1Relation"} />
        <input type={"text"} name={"ref1Phone"} />
        <input type={"text"} name={"ref2Name"} />
        <input type={"text"} name={"ref2Relation"} />
        <input type={"text"} name={"ref2Phone"} />
        <input type={"text"} name={"ref3Name"} />
        <input type={"text"} name={"ref3Relation"} />
        <input type={"text"} name={"ref3Phone"} />
        <input type={"file"} name={"attachment"} />
        <input type={"text"} name={"sig"} />
      </form>
    </Layout>
  );
}
