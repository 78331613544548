import React from "react";

const StepBanner = ({ currentStep }) => {
  // Define the tabs text
  const tabs = [
    "General Information",
    "Education / Experience",
    "Employment / References",
    "Upload Resume",
    "Sign and Submit",
  ];

  // Define the arrow and block images
  const arrows = [
    "https://i.ibb.co/DwNs7zG/Steps.png",
    "https://i.ibb.co/wNZ4nzy/Steps2.png",
    "https://i.ibb.co/c2k4Gbr/Steps3.png",
    "https://i.ibb.co/XCdjrhm/Steps4.png",
  ];

  // Function to determine which arrow to use based on the index
  const getArrow = (index) => {
    if (index < currentStep - 1) {
      return arrows[0]; // Completed step - indigo
    } else if (index === currentStep - 1) {
      return arrows[1]; // Current step - light blue
    } else {
      return arrows[2]; // Future step - white
    }
  };

  // Function to determine the text color based on the index
  const getTextColor = (index) => {
    if (index < currentStep - 1) {
      return "text-white"; // Completed step - white text
    } else if (index === currentStep - 1) {
      return "text-indigo"; // Current step - indigo text
    } else {
      return "text-black"; // Future step - black text
    }
  };

  // Render the banner with appropriate arrows and text colors
  return (
    <div className="py-12 flex flex-wrap items-center justify-center">
      {tabs.slice(0, -1).map((tab, index) => (
        <div key={index} className="w-52 h-16 relative md:mt-0 mt-4">
          <img src={getArrow(index)} alt={`step${index + 1}`} className="w-full h-full" />
          <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
            <p className={`w-full text-sm font-medium leading-4 ${getTextColor(index)}`}>{tab}</p>
          </div>
        </div>
      ))}
      <div className="w-52 h-16 relative md:mt-0 mt-4">
        <img src={arrows[3]} alt="step5" className="w-full h-full" />
        <div className="absolute w-full flex flex-col px-6 items-center justify-center inset-0 m-0">
          <p className="w-full text-sm font-medium leading-4 text-black">{tabs[4]}</p>
        </div>
      </div>
    </div>
  );
};

export default StepBanner;
