import React from "react";
import { useForm } from "react-hook-form";
import StepBanner from "./StepBanner";

const AppPage1 = (props) => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const { data, handleChange, next } = props;
  return (
    <>
      <StepBanner currentStep={1} />
      <div className="flex items-center justify-center mb-10">
        <div className="xl:w-10/12 w-full px-8">
          <div className="xl:px-24">
            <div className="px-5 py-4 border-b rounded-lg flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 9.99999H20C20.2652 9.99999 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 9.99999 4 9.99999H5V8.99999C5 8.08074 5.18106 7.17049 5.53284 6.32121C5.88463 5.47193 6.40024 4.70026 7.05025 4.05025C7.70026 3.40023 8.47194 2.88462 9.32122 2.53284C10.1705 2.18105 11.0807 1.99999 12 1.99999C12.9193 1.99999 13.8295 2.18105 14.6788 2.53284C15.5281 2.88462 16.2997 3.40023 16.9497 4.05025C17.5998 4.70026 18.1154 5.47193 18.4672 6.32121C18.8189 7.17049 19 8.08074 19 8.99999V9.99999ZM17 9.99999V8.99999C17 7.67391 16.4732 6.40214 15.5355 5.46446C14.5979 4.52678 13.3261 3.99999 12 3.99999C10.6739 3.99999 9.40215 4.52678 8.46447 5.46446C7.52678 6.40214 7 7.67391 7 8.99999V9.99999H17ZM11 14V18H13V14H11Z"
                      fill="#4B5563"
                    />
                  </svg>
                </div>
                <p className="text-sm pl-3">
                  We take privacy issues seriously. You can be sure that your
                  personal data is securely protected.
                </p>
              </div>
            </div>

            <div className="mt-16 lg:flex justify-between border-b border-gray pb-16">
              <div className="left-section lg:w-2/6">
                <div className="test flex items-center">
                  <h1 className="text-xl font-medium pr-2 leading-5">
                    Personal Information:
                  </h1>
                </div>
                <p className="mt-4 text-sm leading-5">
                  Please provide the following information about yourself.
                </p>
              </div>
              <div className="right-section lg:w-4/6">
                <div className="md:flex md:justify-around lg:items-center lg:ml-24 lg:mt-0 mt-4">
                  <div className="md:w-full md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="firstName"
                      id="firstName"
                    >
                      First Name:
                    </label>
                    <input
                      {...register("firstName", {
                        required: true,
                        maxLength: 30,
                      })}
                      type="text"
                      name="firstName"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="firstName"
                      placeholder="First Name"
                      value={data.firstName}
                      onChange={handleChange}
                      required={true}
                    />
                    {errors.firstName && (
                      <p id="error" className="text-red">
                        First Name Is Required
                      </p>
                    )}
                  </div>
                  <div className="md:w-full md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="lastName"
                      id="lastName"
                    >
                      Last Name:
                    </label>
                    <input
                      {...register("lastName", {
                        required: true,
                        maxLength: 50,
                      })}
                      type="text"
                      name="lastName"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="lastName"
                      placeholder="Last Name"
                      value={data.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <p id="error" className="text-red">
                        Last Name Is Required
                      </p>
                    )}
                  </div>
                </div>

                <div className="md:flex items-center lg:ml-24 mt-8">
                  <div className="md:w-full">
                    <label
                      className="text-sm leading-none"
                      htmlFor="homeAddress"
                      id="homeAddress"
                    >
                      Home Address:
                    </label>
                    <input
                      {...register("homeAddress", { required: true })}
                      type="text"
                      name="homeAddress"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="homeAddress"
                      placeholder="Street"
                      value={data.homeAddress}
                      onChange={handleChange}
                    />
                    {errors.homeAddress && (
                      <p id="error" className="text-red">
                        Home Address Is Required
                      </p>
                    )}
                  </div>
                </div>

                {/* City, State, and Zip Code */}
                <div className="md:flex md:justify-around lg:items-center lg:ml-24 lg:mt-8 mt-4">
                  {/* City */}
                  <div className="md:w-full md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="city"
                      id="city"
                    >
                      City:
                    </label>
                    <input
                      {...register("city", { required: true })}
                      type="text"
                      name="city"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="city"
                      placeholder="City"
                      value={data.city}
                      onChange={handleChange}
                    />
                    {errors.city && (
                      <p id="error" className="text-red">
                        City Is Required
                      </p>
                    )}
                  </div>
                  {/* State */}
                  <div className="md:w-full md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="state"
                      id="state"
                    >
                      State:
                    </label>
                    <input
                      {...register("state", { required: true })}
                      type="text"
                      name="state"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="state"
                      placeholder="State"
                      value={data.state}
                      onChange={handleChange}
                    />
                    {errors.state && (
                      <p id="error" className="text-red">
                        State Is Required
                      </p>
                    )}
                  </div>
                  {/* Zip Code */}
                  <div className="md:w-full md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="zipCode"
                      id="zipCode"
                    >
                      Zip Code:
                    </label>
                    <input
                      {...register("zipCode", { required: true })}
                      type="text"
                      name="zipCode"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="zipCode"
                      placeholder="Zip Code"
                      value={data.zipCode}
                      onChange={handleChange}
                    />
                    {errors.zipCode && (
                      <p id="error" className="text-red">
                        Zip Code Is Required
                      </p>
                    )}
                  </div>
                </div>

                <div className="md:flex md:justify-around lg:items-center  lg:ml-24 lg:mt-8 mt-4">
                  <div className="md:w-full  md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="socialSecurity"
                      id="sS"
                    >
                      Social Security:
                    </label>
                    <input
                      {...register("socialSecurity", { required: true })}
                      type="password"
                      name="socialSecurity"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="socialSecurity"
                      placeholder="xxx-xx-xxxx"
                      value={data.socialSecurity}
                      onChange={handleChange}
                    />
                    {errors.socialSecurity && (
                      <p id="error" className="text-red">
                        Social Security Number Is Required
                      </p>
                    )}
                  </div>
                  <div className="md:w-full md:ml-6">
                    <label
                      className="text-sm leading-none"
                      htmlFor="phoneNumber"
                      id="phoneNumber"
                    >
                      Phone Number:
                    </label>
                    <input
                      {...register("phoneNumber", { required: true })}
                      type="tel"
                      name="phoneNumber"
                      // pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="phoneNumber"
                      placeholder="###-###-####"
                      value={data.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <p id="error" className="text-red">
                        Phone Number Is Required
                      </p>
                    )}
                  </div>
                </div>
                <div className="md:flex items-center lg:ml-24 mt-8">
                  <div className="md:w-full">
                    <label
                      className="text-sm leading-none"
                      htmlFor="emailAddress"
                      id="emailAddress"
                    >
                      Email Address:
                    </label>
                    <input
                      {...register("emailAddress", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      type="email"
                      name="emailAddress"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="emailAddress"
                      placeholder="username@gmail.com"
                      value={data.emailAddress}
                      onChange={handleChange}
                      required={true}
                    />
                    {errors.emailAddress && (
                      <p id="error" className="text-red">
                        Email Address Is Required
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16 lg:flex justify-between border-b border-gray pb-16 mb-4">
              <div className="left-section lg:w-2/6">
                <div className="flex items-center">
                  <h1 className="text-xl font-medium pr-2 leading-5">
                    Position Applying:
                  </h1>
                </div>
                <p className="mt-4 text-sm leading-5">
                  Please provide the following information about the position
                  you are applying for.
                </p>
              </div>
              <div className="right-section lg:w-4/6">
                <div className="md:flex md:justify-around lg:items-center lg:ml-24 lg:mt-0 mt-4">
                  <div className="md:w-full  md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor={"workType"}
                      id="workType"
                    >
                      Type of Employment:
                    </label>
                    <select
                      {...register("workType", { required: true })}
                      name="workType"
                      required={true}
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      onChange={handleChange}
                      value={data.workType}
                    >
                      <option value="" disabled>
                        Select One
                      </option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                      <option value={"Open"}>Open</option>
                    </select>
                    {errors.workType && (
                      <p id="error" className="text-red">
                        Work Type Is Required
                      </p>
                    )}
                  </div>
                  <div className="md:w-full md:ml-6 md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor={"desiredPosition"}
                      id={"desiredPosition"}
                    >
                      Position Applying For:
                    </label>
                    <select
                      {...register("desiredPosition", { required: true })}
                      name="desiredPosition"
                      required={true}
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      onChange={handleChange}
                      value={data.desiredPosition}
                    >
                      <option value="" disabled>
                        Select One
                      </option>
                      <option value="Case Manager">Case Manager</option>
                      <option value="Administration">Administration</option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.desiredPosition && (
                      <p id="error" className="text-red">
                        Position Is Required
                      </p>
                    )}
                  </div>
                </div>
                <div className="md:flex md:justify-around lg:items-center  lg:ml-24 lg:mt-8 mt-4">
                  <div className="md:w-full md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="howDiscovered"
                      id="howDiscovered"
                    >
                      How did you learn of this opening?
                    </label>
                    <input
                      {...register("howDiscovered", { required: true })}
                      type="text"
                      name="howDiscovered"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="howDiscovered"
                      placeholder="How did you learn of this opening?"
                      value={data.howDiscovered}
                      onChange={handleChange}
                    />
                    {errors.howDiscovered && (
                      <p id="error" className="text-red">
                        This Field Is Required!
                      </p>
                    )}
                  </div>
                  <div className="md:w-full md:ml-6">
                    <label
                      className="text-sm leading-none"
                      htmlFor="referredBy"
                      id="referredBy"
                    >
                      If referred by someone, please list the person's name:
                    </label>
                    <input
                      {...register("referredBy", { required: false })}
                      type="text"
                      name="referredBy"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="referredBy"
                      placeholder="Person’s Name"
                      value={data.referredBy}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="md:flex md:justify-around lg:items-center lg:ml-24 lg:mt-8 mt-4">
                  <div className="md:w-full md:mt-0 mt-4">
                    <label
                      className="text-sm leading-none"
                      htmlFor="availableStartDate"
                      id="availableStartDate"
                    >
                      If hired, date available to start:
                    </label>
                    <input
                      {...register("availableStartDate", { required: true })}
                      type="text"
                      name="availableStartDate"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="availableStartDate"
                      placeholder="##/##/####"
                      value={data.availableStartDate}
                      onChange={handleChange}
                    />
                    {errors.availableStartDate && (
                      <p id="error" className="text-red">
                        Start Date Is Required!
                      </p>
                    )}
                  </div>
                  <div className="md:w-full md:ml-6">
                    <label
                      className="text-sm leading-none"
                      htmlFor="desiredPayRate"
                      id="desiredPayRate"
                    >
                      Desired pay rate:
                    </label>
                    <input
                      {...register("desiredPayRate", { required: false })}
                      type="text"
                      name="desiredPayRate"
                      tabIndex="0"
                      className="w-full p-3 mt-3 bg-app-bg border rounded border-gray focus:outline-none focus:border-gray text-sm font-medium leading-none "
                      aria-labelledby="desiredPayRate"
                      placeholder="Rate/Hr"
                      value={data.desiredPayRate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-btn-container flex -mt-8 mb-6 justify-around">
        <button
          onClick={async (e) => {
            e.preventDefault();
            const result = await trigger();
            if (result === true) {
              next();
            } else {
              return null;
            }
          }}
          className="rounded-full p-3 bg-bristol-blue-2 bg-opacity-50"
        >
          Next Page →
        </button>
      </div>
    </>
  );
};

export default AppPage1;
